import React from 'react'

import LayoutContent from '../components/layout/layoutContent'
import AuthError from '../components/common/authError'

const AuthErrorPage = props => (
    <LayoutContent {...props} hasVerticalGutters>
         <AuthError {...props} />
    </LayoutContent>
)

export default AuthErrorPage
